import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import {
    BannerPage,
    ContainerContent,
    ContainerContentFixed,
    DisplayReviewList,
    Layout,
    SEO,
} from "../components";

const Reviews = () => {
    const data = useStaticQuery(graphql`
    query {
      allReviewsJson {
        nodes {
          client          
          platform
          review                          
        }
      }
    }
  `);
    const reviews = data.allReviewsJson.nodes;
    console.log(reviews)
    return (
        <Layout>
            <SEO
                title="Client Reviews"
                description={"Client Reviews from Facebook, Google, etc."}
            />
            <BannerPage
                title={"Client Reviews"}
                subTitle={"Client Reviews from Facebook, Google, etc."}
            />
            <ContainerContent>
                <ContainerContentFixed>
                    <DisplayReviewList reviews={reviews} />
                </ContainerContentFixed>
            </ContainerContent>
        </Layout>
    );
};

export default Reviews;
